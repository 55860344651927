export const environment = {
  baseEndpoint: "https://api-qa.trycycle.io/clinician/",  
  secondEndpoint: 'https://api.ca.trycycle.io/admin/',
  googleMapsKey: 'AIzaSyCfcEcQQiRossHyIq3X0_YwUr22GYRReNc',  
  buildNumber: "28958203",  
  versionNumber: "5.3.10",  
  environment_deploy: "qa",  
  rayGunKey: '43wdWdJaHTd1ENpkAYZ53g',  
  authKey: 'd1d79943-e2c8-453c-8cc9-ef99e4d8ebd7',
  cryptoKey: 'd7db6623-3c23-431b-b7dc-3c636d42734e',  
  chromiumMinNumber: '110',
  safariMinNumber: '16',
  parrotPosthogToken: 'phc_C7CtUKlKTfK5YgaWv9YgSpLeK6KWwX9hvH0MUnycbCX', 
};
